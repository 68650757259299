import React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/Layout";

export default function () {
  return (
    <Layout title="Heidekojoten - 404" description="Seite nicht gefunden">
      <div className="min-h-screen bg-black text-primary-500 py-24">
        <div className="text-center container space-y-12">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="ml-2 md:ml-4">
              <h1 className="text-3xl md:text-6xl uppercase font-extrabold">
                Heidekojoten
              </h1>
              <h2 className="text-lg leading-none ml-1 md:text-3xl">
                Der BVB Fanclub für Soltau und Umgebung.
              </h2>
            </div>
          </div>
          <div className="space-y-12 ">
            <h1 className="text-3xl text-red-500 font-bold">
              404 - Seite nicht gefunden
            </h1>
            <p className="text-lg">
              Diese Seite konnte leider nicht gefunden werden. Bitte überprüfen
              Sie die URL auf Fehler und versuchen Sie es erneut.
            </p>
            <p>
              <Link to="/" className="text-info font-bold underline">
                Zurück zur Homepage
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
